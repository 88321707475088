'use strict';

let jsonPageData = {};

function loadData() {
  jsonPageData = {};
  let jsonPageDataTag = document.getElementById('jsonPageData');

  if (jsonPageDataTag) {
    jsonPageData = JSON.parse(jsonPageDataTag.innerHTML);
  }
  return jsonPageData;
}

function getData() {
  return  jsonPageData;
}

function refetchData() {
  return loadData();
}

function setDataFromResponse(response) {
  if (response.jsonPageData != null) {
    jsonPageData = response.jsonPageData;
    window.jsonPageData = jsonPageData;
  }
}
loadData();

export default {
  getData, refetchData, setDataFromResponse
}
