//simple feature detection (like Modernizr but smaller)

//css object-fit
function objectFit(){
  if('objectFit' in document.documentElement.style === false) {
    return 'no-objectfit';
  }
  return 'objectfit';
}


function svgCssTransforms(){
  const vendorPrefixesCss = ['-moz-', '-ms-', '-webkit-', ''],

    styleRules = vendorPrefixesCss.reduce( (prev, curr) => {
      return prev + curr + 'transform: translateX(10px); ';
    }, '' ),

    body = document.querySelector('body'),
    svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  svg.appendChild(path);
  svg.style.cssText = 'width: 100px; visibility: transparent;';
  body.appendChild(svg);

  // getBoundingClientRect to determine if transform applied successfully.
  const initialBoundingRect = path.getBoundingClientRect();
  path.style.cssText = styleRules;
  const newBoundingRect = path.getBoundingClientRect();

  body.removeChild(svg);

  if(initialBoundingRect.left != newBoundingRect.left){
    return 'svgCssTransforms';
  }
  return 'no-svgCssTransforms';
}

function initFeatureDetection(){
  const existingClassNames = document.documentElement.className;
  const classNames = [
    existingClassNames,
    objectFit(),
    svgCssTransforms()
  ];
  document.documentElement.className = classNames.join(' ').trim();
}

export default initFeatureDetection;

