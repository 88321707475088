import Events from 'events/events'
import {dataset} from '../polyfills/dataset'
import delay from 'helpers/delay'
import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach';
import checkIfSelectedFilters from './accordion/checkIfSelectedFilters';

'use strict';

const CONTENT_TRANSITION_TIME_SECONDS = 0.2;
const CONTENT_TRANSITION_TIME_MILLISECONDS = CONTENT_TRANSITION_TIME_SECONDS * 1000;

var index = 1;

var Accordion = function(container) {
  var _this = this;

  function setup() {
    initialiseAttributes();

    if (_this.dataset.accordionInitialised) {
      return;
    }

    _this.dataset.accordionInitialised = 'true';

    // store the current visible section - used if the accordion is
    // configured to collapse one section when another section is expanded
    _this.currentExpandedSection = _this.shouldHidePreviousSection ? null : _this.shouldHidePreviousSection;

    let accordionSections = container.querySelectorAll('.' + _this.sectionName);

    for ( let section of accordionSections ) {
      let isExpandedByDefault = _this.defaultVisibleAccordionSection ? section.classList.contains(_this.defaultVisibleAccordionSection) : false,
        sectionControl = section.querySelector('.' + _this.sectionControlName),
        sectionContent = section.querySelector('.' + _this.sectionContentName),
        sectionMinHeight = calculateMinimumHeightFromContent(sectionContent);

      if(sectionMinHeight === -1) {
        continue;
      }

      _this.currentExpandedSection = (_this.currentExpandedSection && isExpandedByDefault) ? section : _this.currentExpandedSection;


      if(!sectionContent) {
        continue;
      }

      let newAccordionSection =
      new AccordionSection(
          section,
          _this,
          index,
          isExpandedByDefault,
          sectionControl,
          sectionContent,
          _this.expandButtonText,
          _this.contractButtonText,
          sectionMinHeight,
          _this.customButtonClass
        );

      // accepts a value passed via data-accordion-default-open attribute on the container
      // this pre-opens that defined number of accordions in the set
      if( index < _this.defaultOpenCount ) {
        newAccordionSection.isExpandedByDefault = true;
      }

      // Added to handle expansion of product description on desktop PDP
      let desktopVisibleClass = newAccordionSection.dataset.defaultVisibleDesktop;
      if( newAccordionSection.section.classList.contains( desktopVisibleClass ) && window.innerWidth > 960 ) newAccordionSection.isExpandedByDefault = true;

      checkIfSelectedFilters(newAccordionSection);

      if(newAccordionSection.isExpandedByDefault) {
        newAccordionSection.expand(false);
        _this.currentExpandedSection = _this.hasOwnProperty("currentExpandedSection") ? newAccordionSection : _this.currentExpandedSection;
      } else {
        newAccordionSection.contract(false);
      }

      _this.hasSpecialOffer = ('product-detail--special-offer' == section.getAttribute('id')) ? true : _this.hasSpecialOffer;

      _this.sections.push(newAccordionSection);
      index++;
    }

    addEventListeners();
  }

  function initialiseAttributes() {
    _this.container = container;
    _this.dataset = dataset(_this.container);
    _this.sectionName = _this.dataset.accordionSection;
    _this.sectionControlName = _this.dataset.accordionControl;
    _this.sectionContentName = _this.dataset.accordionContent;
    _this.expandButtonText = _this.dataset.accordionExpandButtonText;
    _this.contractButtonText = _this.dataset.accordionContractButtonText;
    _this.repeatingContentCount = _this.dataset.accordionRepeatingContentCount;

    _this.repeatingContentClass = _this.dataset.accordionRepeatingContentClass;
    _this.repeatingContentInitialRows = _this.dataset.accordionRepeatingContentInitialRows;
    _this.repeatingContentItemsPerRow = _this.dataset.accordionRepeatingContentItemsPerRow;
    _this.customButtonClass = _this.dataset.accordionCustomButtonClass;
    _this.customButtonPosition = _this.dataset.accordionCustomButtonPosition;

    _this.defaultOpenCount = parseInt(_this.dataset.accordionDefaultOpen, 10);

    _this.sections = [];
    _this.shouldHidePreviousSection = _this.dataset.accordionHasSingleVisibleSection === 'true' ? true : false;
    _this.hasSpecialOffer = false;
    _this.defaultVisibleAccordionSection = _this.dataset.defaultVisibleSection || false;
    _this.hasResized = false;
  }

  function calculateMinimumHeightFromContent(section) {
    if (_this.repeatingContentClass === undefined) {
      return 0;
    }
    let contentIndex = 0,
      contentContainers = section.querySelectorAll(_this.repeatingContentClass),
      contentItemsPerRow = _this.repeatingContentItemsPerRow,
      contentItemsToShow = contentItemsPerRow * _this.repeatingContentInitialRows,
      contentCount = contentContainers.length,
      minimumHeight = 0;

    if(contentCount <= contentItemsToShow) {
      return -1;
    }

    for(contentIndex = 0; contentIndex < contentItemsToShow; contentIndex++) {
      if((contentIndex % contentItemsPerRow) === 0) {
        minimumHeight += parseInt(getTotalHeightFromObject(contentContainers[contentIndex]), 10);
      }
    }

    //minimumHeight += parseInt(window.getComputedStyle(_this.container).getPropertyValue('margin-top'));

    return minimumHeight;
  }

  function addEventListeners() {
    Events.accordionWillUpdate.register(function(sender) {
      if (sender.parent === _this) {
        if (sender.isExpanded) {
          sender.contract();
        } else {
          checkForLateImageLoad(sender);
          checkForLateVideoLoad(sender);
        }
        contractPreviousSectionIfNecessary(sender);
      }
    });

    Events.accordionWillOpen.register(function(sender) {
      if (sender.parent === _this) {
        if (!sender.isExpanded) {
          adjustSectionHeight(sender.section);
          sender.expand();
          contractPreviousSectionIfNecessary(sender);
        }
      }
    });

    Events.accordionContentUpdated.register(function(sender) {
      let matchedSections = _this.sections.filter(function(accordionSection) {
        return accordionSection.section === sender;
      });

      matchedSections.forEach(function(matchedSection) {
        adjustSectionHeight(matchedSection);
      });
    });

    _this.container.addEventListener(Events.accordionShouldOpen.type, function() {
      if (_this.sections.length > 0){
        let detailsSectionIndex = 0;
        if(_this.hasSpecialOffer) {
          detailsSectionIndex = 1;
        }
        Events.accordionWillOpen.trigger(_this.sections[detailsSectionIndex], null);
      }
    });

    function contractPreviousSectionIfNecessary(sender) {
      if (_this.shouldHidePreviousSection) {
        if(_this.currentExpandedSection) {
          _this.currentExpandedSection.contract();
        }
        _this.currentExpandedSection =
         sender === _this.currentExpandedSection ?
           null : sender;
      }
    }

    function adjustAllSectionHeights() {
      _this.sections.forEach(function(section) {
        adjustSectionHeight(section);
      });
    }

    function adjustSectionHeight(section) {
      if (typeof section.setMaxHeight === 'function') {
        section.setMaxHeight();
      }
      if (section.isExpanded) {
        section.expand(false);
      }
    }

    function checkForLateImageLoad(sender) {
      let sectionImg = sender.section.querySelector('.accordion-content img');

      if(sectionImg){
        let sectionImgSrc = sectionImg.getAttribute('src');

        var img = new Image();
        img.onload = function() {
          adjustSectionHeight(sender);
          sender.expand();
        }
        img.src = sectionImgSrc;
      }
      else{
        sender.expand();
      }
    }

    function checkForLateVideoLoad(sender) {
      const videos = sender.section.querySelectorAll('.cms-video-rsp');
      if (videos.length > 0 && !_this.hasResized) {
        videos.forEach(video => {
          video.style.height = `${(video.offsetWidth * 9)/16}px`;
        });
        adjustSectionHeight(sender);
      }
      sender.expand();
    }

    function resetFixedVideoHeight() {
      if(!_this.hasResized) {
        const videos = container.querySelectorAll('.cms-video-rsp');
        if (videos.length > 0) {
          videos.forEach(video => {
            video.style.height = 'auto';
          });
          _this.hasResized = true;
        }
      }
    }

    Events.accordionWillAdjustHeight.register(adjustAllSectionHeights);
    window.addEventListener('resize', () => {
      debounce(adjustAllSectionHeights, CONTENT_TRANSITION_TIME_MILLISECONDS)();
      resetFixedVideoHeight();
    });
  }

  _this.setCurrentExpandedSection = function(section) {
    _this.currentExpandedSection = section;
  }

  setup();
}

var AccordionSection = function(section, parent, index, isExpandedByDefault, control,
  content, expandButtonText, contractButtonText, sectionMinHeight, customButton) {
  var _this = this;

  _this.customButton = null;
  _this.isExpandedByDefault = !!isExpandedByDefault;
  _this.section = section;
  _this.parent = parent;
  _this.dataset = { ...dataset(_this.section), ...dataset(_this.parent.container) };
  _this.expandButtonText =  _this.dataset.accordionExpandButtonText ? _this.dataset.accordionExpandButtonText : expandButtonText;
  _this.contractButtonText =  _this.dataset.accordionContractButtonText ? _this.dataset.accordionContractButtonText : contractButtonText;
  _this.minHeight = sectionMinHeight;
  _this.processingTabindexes = false;

  _this.index = index;

  _this.focusOnExpand = _this.dataset.accordionFocusOnExpand != 'false';
  _this.focusOnContract = _this.dataset.accordionFocusOnContract != 'false';

  _this.overrideKeyboardTabbing = (_this.dataset.accordionKeyboardOverride && _this.dataset.accordionKeyboardOverride === 'true') || false;
  _this.tabIndexAttribute = 'data-original-tabindex'

  _this.expand = function(shouldRefocus = _this.focusOnExpand) {
    _this.disableDuringAnimation(shouldRefocus);

    const shouldCalculateMaxHeight = !_this.control.classList.contains('is-expanded') ||
      /cms-accordion-item[0-9]+/.test(_this.section.className);

    if (shouldCalculateMaxHeight) {
      _this.content.style.height = _this.maxHeight + "px";
      _this.control.classList.add('is-expanded');
      _this.control.classList.remove('is-contracted');
    }
    _this.control.setAttribute("aria-expanded", "true");
    _this.content.setAttribute("aria-hidden", "false");
    if(_this.contractButtonText !== undefined && _this.customButton === null) {
      _this.control.firstChild.nodeValue = _this.contractButtonText;
    } else if (_this.customButton) {
      handleCustomExpandContract(true);
    }

    if(_this.overrideKeyboardTabbing) handleKeyboardTabAccess('expand');

    _this.isExpanded = true;

    // Sometimes maxHeight is not calculated quite correctly. Adjust for this now.
    delay(CONTENT_TRANSITION_TIME_MILLISECONDS, () => {
      _this.content.style.overflow = 'visible';
    });

    return false;
  }

  _this.contract = function(shouldRefocus = _this.focusOnContract) {
    _this.disableDuringAnimation(shouldRefocus);
    _this.content.style.height = _this.minHeight + "px";
    _this.content.style.overflow = 'hidden';
    _this.control.classList.add('is-contracted');
    _this.control.classList.remove('is-expanded');
    _this.control.setAttribute("aria-expanded", "false");
    _this.content.setAttribute("aria-hidden", "true");
    if(_this.expandButtonText !== undefined && _this.customButton === null) {
      _this.control.firstChild.nodeValue = _this.expandButtonText;
    } else if (_this.customButton) {
      handleCustomExpandContract(false);
    }

    if(_this.overrideKeyboardTabbing) handleKeyboardTabAccess('contract');

    _this.isExpanded = false;

    return false;
  }

  function handleCustomExpandContract(isExpanded){
    let addClass = isExpanded ? 'button--contract' : 'button--expand';
    let removeClass = isExpanded ? 'button--expand' : 'button--contract';
    let buttonText = isExpanded ? _this.contractButtonText : _this.expandButtonText;
    let customButtonObject = _this.customButton.querySelector('button');
    customButtonObject.firstChild.nodeValue = buttonText;
    customButtonObject.classList.add(addClass);
    customButtonObject.classList.remove(removeClass);
  }

  function handleKeyboardTabAccess(direction) {

    // capture event bubbling scenario where this is called twice
    if(_this.processingTabindexes == direction || !_this.tabbableEls) return;

    // set flag
    _this.processingTabindexes == direction;

    // loop over each tabbable element
    forEach(_this.tabbableEls, (element) => {
      // grab the original tabindex (if it exists)
      const storedTabindex = element.getAttribute(_this.tabIndexAttribute);
      // set the new tabindex: if we are contracting, then always -1; otherwise it is whatever
      // we had stored, or zero
      const newTabindex = (direction === 'contract') ? '-1' : (storedTabindex ? storedTabindex : 0)

      // set current index
      element.setAttribute('tabindex', newTabindex);
    });
  }

  _this.setMaxHeight = function() {
    const searchChild = _this.content.querySelector('.facets-filter-search');
    const scrollHeight = _this.content.scrollHeight;

    _this.maxHeight = scrollHeight;

    if(searchChild) {
      setTimeout(() => {
        const searchHidden = searchChild.classList.contains('facets-filter-search--hide');
        _this.maxHeight = searchHidden ? scrollHeight : scrollHeight + 48;

        if(_this.isExpanded) {
          _this.expand(false);
        }
      }, 500);
    }
  }

  _this.disableDuringAnimation = function(shouldRefocus) {
    _this.control.setAttribute("disabled", "disabled");
    delay(CONTENT_TRANSITION_TIME_MILLISECONDS, () => {
      _this.control.removeAttribute("disabled");
      if (shouldRefocus) {
        _this.control.focus();
      }
    });
  }

  function setup () {

    // make sure the section is position relative so measurements are accurate
    _this.section.style.position = "relative";

    // wrap content in a div for easier control of height
    _this.content = document.createElement('div');
    _this.content.classList.add('accordion-content');
    _this.content.style.transition = `height ${CONTENT_TRANSITION_TIME_SECONDS}s ease-in`;
    content.parentNode.appendChild(_this.content);
    _this.content.appendChild(content);

    _this.content.setAttribute("id", "accordion-" + index);
    _this.content.setAttribute("aria-hidden", "false");

    if (control) {
      if(control.tagName.match(/^h[1-6]$/ig)) {
        // wrap contents in a button
        let controlWrapper = control;

        // TODO - resolve properly
        // let controlText = DomUtils.getTextFromNode(controlWrapper, true);
        let controlText = controlWrapper.innerHTML;

        _this.control = handleButtonCreation(controlText, ['accordion-control', 'accordion-control--heading'], customButton);

        // put the button inside the heading tag

        // TODO - resolve properly
        while (controlWrapper.firstChild) {
          controlWrapper.removeChild(controlWrapper.firstChild);
        }

        controlWrapper.appendChild(_this.control);

      } else {
        _this.control = control;
        _this.control.setAttribute('role', 'button');
      }
    } else {
      _this.control = handleButtonCreation(_this.expandButtonText, 'accordion-control', customButton);
      let insertAfterObject = (_this.parent.customButtonPosition === "below") ? null : _this.content;
      _this.section.insertBefore(_this.control, insertAfterObject);
    }

    _this.control.setAttribute("aria-controls", "accordion-" + index);
    _this.control.setAttribute("aria-expanded", "true");

    _this.isExpanded = false;

    _this.maxHeight = 10;

    if (typeof _this.content.clientHeight != 'undefined') {
      _this.setMaxHeight();
    }

    if (_this.overrideKeyboardTabbing) {
      // grab all focusable elements, which do not have tabindex of -1 already assigned to them
      _this.tabbableEls = _this.content.querySelectorAll('a:not([tabindex="-1"]), input:not([tabindex="-1"]), button:not([tabindex="-1"])');

      // loop over each, and if they have an existing tabindex, then store it as a data attribute
      forEach(_this.tabbableEls, (element) => {
        const originalTabindex = element.getAttribute('tabindex');

        if(originalTabindex) {
          element.setAttribute(_this.tabIndexAttribute, originalTabindex);
        }
      });
    }

    addEventListeners();
  }

  function addEventListeners() {
    _this.control.addEventListener('click', function(e) {
      Events.accordionWillUpdate.trigger(_this, null);
      e.preventDefault();
    });

    _this.section.addEventListener(Events.accordionShouldOpen.type, function() {
      Events.accordionWillOpen.trigger(_this, null);
    });
  }

  function handleButtonCreation(content, classList, customButton) {
    if(customButton) {
      _this.customButton = createCustomButton(content, classList, customButton, _this.section);
      return _this.customButton;
    } else {
      return createButton(content, classList);
    }
  }

  setup();
}

function createCustomButton(content, classList, customButton, section) {
  let customButtonObject = section.parentElement.querySelector(customButton);
  if(customButtonObject){
    customButtonObject.classList.remove("u-hidden");
    return customButtonObject;
  } else {
    return createButton(content, classList);
  }
}

function createButton(content, classList) {
  let button = document.createElement('button');
  let buttonText = content || "Toggle accordion";
  button.setAttribute('type', 'button');
  button.innerHTML = buttonText;
  if (classList) {
    if (typeof classList === 'string') {
      button.classList.add(classList);
    }
    if (Array.isArray(classList)) {
      classList.forEach(function(className) {
        button.classList.add(className);
      }
      );
    }
  }
  return button;
}

function getTotalHeightFromObject(selector){
  let elHeight = selector.offsetHeight;
  //elHeight += parseInt(window.getComputedStyle(selector).getPropertyValue('margin-top'));
  elHeight += parseInt(window.getComputedStyle(selector).getPropertyValue('margin-bottom'));

  return elHeight;
}

export function init() {
  index = 0;
  const accordionContainers = document.querySelectorAll('*[data-accordion-content]');

  for (let accordionContainer of accordionContainers) {
    if (dataset(accordionContainer).accordionAutoInit !== 'false') {
      new Accordion(accordionContainer);
    }
  }
}

export function initAccordions() {
  init();
}

if ('querySelector' in document) {
  init();
  Events.accordionReinit.register(init);
}

export default Accordion
