import { PubSubEvent } from './pubSubEvent'

var Events = {
  searchDidClose : new PubSubEvent('searchDidClose'),
  searchWillOpen: new PubSubEvent('searchWillOpen'),
  searchDidOpen : new PubSubEvent('searchDidOpen'),
  searchWillClose : new PubSubEvent('searchWillClose'),
  toggleNavigation : new PubSubEvent('toggleNavigation'),
  menuDidOpen : new PubSubEvent('menuDidOpen'),
  menuWillOpen: new PubSubEvent('menuWillOpen'),
  menuDidClose : new PubSubEvent('menuDidClose'),
  menuWillClose : new PubSubEvent('menuWillClose'),
  carouselResize : new PubSubEvent('carouselResize'),
  notificationChangesAvailable: new PubSubEvent('notificationChangesAvailable'),
  notificationClosed: new PubSubEvent('notificationClosed'),
  quantityFieldsReplaced: new PubSubEvent('quantityFieldsReplaced'),
  quantityUpdated: new PubSubEvent('quantityUpdated'),
  accordionWillUpdate : new  PubSubEvent('accordionWillUpdate'),
  accordionWillOpen : new  PubSubEvent('accordionWillOpen'),
  accordionWillAdjustHeight : new  PubSubEvent('accordionWillAdjustHeight'),
  onAddToBasket : new PubSubEvent('onAddToBasket'),
  onAddToBasketError : new PubSubEvent('onAddToBasketError'),
  basketQuantityUpdated : new PubSubEvent('basketQuantityUpdated'),
  facetListLoaded: new PubSubEvent('facetListLoaded'),
  accordionShouldOpen: new PubSubEvent('accordionShouldOpen', {bubbles: false, cancelable: true}),
  accordionContentUpdated: new PubSubEvent('accordionContentUpdated'),
  sortSelectReinit: new PubSubEvent('sortSelectReinit'),
  activeToggleDidGainFocus: new PubSubEvent('activeToggleDidGainFocus'),
  activeToggleDidReturnDefaultFocus: new PubSubEvent('activeToggleDidReturnDefaultFocus'),
  accordionReinit: new PubSubEvent('accordionReinit'),
  productListUpdated: new PubSubEvent('productListUpdated'),
  pageReRendered: new PubSubEvent('pageReRendered'),
  tooltipsReinit: new PubSubEvent('tooltipsReinit'),
  addToBasketReinit: new PubSubEvent('addToBasketReinit'),
  reviewsReinit: new PubSubEvent('reviewsReinit'),
  modalWillOpen: new PubSubEvent('modalWillOpen'),
  modalWillClose: new PubSubEvent('modalWillClose'),
  compareWillOpen: new PubSubEvent('compareWillOpen'),
  compareDidClose: new PubSubEvent('compareDidClose'),
  swatchOrderWillOpen: new PubSubEvent('swatchOrderWillOpen'),
  swatchOrderDidClose: new PubSubEvent('swatchOrderDidClose'),
  plpOnScroll: new PubSubEvent('plpOnScroll'),
  plpOnResize: new PubSubEvent('plpOnResize'),
  plpOnPageHide: new PubSubEvent('plpOnPageHide'),
  plpBeforeUnload: new PubSubEvent('plpBeforeUnload'),
  plpMobileFacetsOpen: new PubSubEvent('plpMobileFacetsOpen'),
  plpMobileFacetsClose: new PubSubEvent('plpMobileFacetsClose'),
  quickViewEmailMeDone: new PubSubEvent('quickViewEmailMeDone'),
  quickViewATB: new PubSubEvent('quickViewATB'),
  pdpAdditionalPriceAdded: new PubSubEvent('pdpAdditionalPriceAdded'),
  addToBasketSuccess: new PubSubEvent('addToBasketSuccess'),
};

if (typeof window !== 'undefined') {
  window.Events = Events;
}

export default Events;
