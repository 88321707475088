// Object to allow use of custom events
export var PubSubEvent = function(eventName){

  // Store the event's name
  this.eventName = eventName;

  // Array of listeners
  var eventListeners = [];

  // Subscribe to events
  this.register = function(fn){
    eventListeners[eventListeners.length] = fn;
  }

  // Unsubscribe from event
  this.unregister = function(fn){
    for(var i=0; i<eventListeners.length; i++){
      if(eventListeners[i]==fn){
        eventListeners[i]=null;
      }
    }
  }

  // Trigger the event
  this.trigger = function(sender, eventArgs){
    this.eventName = eventName;
    for(var i=0; i<eventListeners.length; i++){
      if(eventListeners[i]){
        eventListeners[i](sender, eventArgs)
      }
    }
  }
}
