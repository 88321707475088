import "babel-polyfill";

import 'element-closest';
import 'string.prototype.endswith';
import 'custom-event-autopolyfill';
import 'whatwg-fetch';
import '../lib/polyfills/array-find';
import '../lib/polyfills/for-each';
import 'classlist-polyfill';
import 'helpers/ensure';

import '../lib/components/accordions';

import initFeatureDetection from '../lib/helpers/feature-detection';

initFeatureDetection();

