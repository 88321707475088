'use strict';

import page from '../pages/page';
// eslint-disable-next-line
import JsonPageData from 'helpers/json-page-data'

page('.shop-details-main', () => {

  const todaysDate = new Date();

  const getTodaysOpeningTimes = () => {
    const days = ['Sun','Mon','Tues','Wed','Thurs','Fri','Sat'];
    const currentDay = days[todaysDate.getDay()];
    const dayList = document.querySelectorAll(".opening-day");
    let foundHours;
    for (let i = 0; i < dayList.length; i++) {
      if (dayList[i].textContent.startsWith(currentDay)) {
        foundHours = dayList[i].nextElementSibling.textContent;
        returnOpenTimesText(foundHours);
        break;
      }
    }
  };

  const returnOpenTimesText = (foundHours) => {
    const times = foundHours.split(" - ");
    if (getCurrentTimeInMins() > getMinutes(times[0]) && getCurrentTimeInMins() < getMinutes(times[1])) {
      document.querySelector('.opening-hours h3').innerText = "Open till " + convertTime(times[1]); + " today";
    } else {
      document.querySelector('.opening-hours h3').innerText = "Now closed";
    }
  }

  const convertTime = (time) => {
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice (1);
      time[5] = +time[0] < 12 ? 'am' : 'pm';
      time[0] = +time[0] % 12 || 12;
    }
    if (time[2] == "00") {
      return time[0]+time[5];
    } else {
      return time.join ('');
    }
  }

  const getCurrentTimeInMins = () => {
    return (todaysDate.getHours()*60+todaysDate.getMinutes());
  }

  const getMinutes = (str) => {
    let time = str.split(':');
    return time[0]*60+time[1]*1;
  }

  const init = () => {
    if(document.querySelector('.shop-details')){
      getTodaysOpeningTimes();
    }
  }

  const canEnhanceStorePage = () => {
    return 'querySelector' in document;
  }

  if (canEnhanceStorePage()) {
    init();
  }
});
