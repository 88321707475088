import page from '../pages/page';

//Not working - we should fix this
page('.store-locator-main_old', () => {
  const TABLET_BREAK_POINT = 768;
  const TOP_PADDING_DELTA = 10;

  const siteWideNav = document.querySelector('header');

  let bodyRect, siteNavHeight;

  const locatorNav = document.querySelector(".store-locator-navigator");
  let locatorNavRect, locatorNavOffset;

  const locatorList = document.querySelector(".store-locator-list");
  let locatorListRect, locatorListTreshold;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  const updateValues = function(initial = false) {
    bodyRect = document.body.getBoundingClientRect();
    siteNavHeight = siteWideNav.offsetHeight;

    if (initial) {
      locatorNavRect = locatorNav.getBoundingClientRect();
      locatorNavOffset = locatorNavRect.top - bodyRect.top;
    }

    locatorListRect = locatorList.getBoundingClientRect();
    locatorListTreshold = locatorListRect.top - bodyRect.top + locatorList.offsetHeight;
  };

  const updateNavigatorStyles = function(keepPadding) {
    locatorNav.classList.remove("store-locator-navigator__fixed");
    locatorNav.classList.remove("store-locator-navigator__pulled");

    locatorNav.style.paddingTop = keepPadding ? siteNavHeight + TOP_PADDING_DELTA + 'px' : '';
  };

  const makeItStick = function() {

    const isMobile = window.innerWidth < TABLET_BREAK_POINT;
    const shouldStick = window.pageYOffset + siteNavHeight >= locatorNavOffset;
    const shouldPullDown = window.pageYOffset >= locatorListTreshold - locatorNav.offsetHeight;
    updateNavigatorStyles(shouldPullDown || shouldStick);

    if (isMobile) {
      if (shouldPullDown) {
        locatorNav.classList.add("store-locator-navigator__pulled");
        return;
      }

      if (shouldStick) {
        locatorNav.classList.add("store-locator-navigator__fixed");
        return;
      }
    }
  };

  const adjustWindowToHash = function() {
    scrollBy(0, -siteNavHeight);
  };

  const shouldReInitialize = function() {
    const windowRatio = windowWidth / windowHeight;
    const newWindowRatio = window.innerWidth / window.innerHeight;
    const ratioChanged = (windowRatio < 1 && newWindowRatio >= 1) || (windowRatio > 1 && newWindowRatio >= 1);
    const modeChanged = (windowWidth < TABLET_BREAK_POINT && window.innerWidth >= TABLET_BREAK_POINT)
      || (windowWidth >= TABLET_BREAK_POINT && window.innerWidth < TABLET_BREAK_POINT);
    return ratioChanged || modeChanged;
  };

  const updateNavigator = function() {
    updateValues();
    makeItStick();
  };

  const initialize = function() {

    updateValues(true);

    if (window.location.hash) {
      adjustWindowToHash();
    }

    window.addEventListener('hashchange', () => {
      adjustWindowToHash();
    }, false);

    window.addEventListener('scroll', () => {
      updateNavigator();
    }, false);

    window.addEventListener('resize', () => {
      shouldReInitialize() && initialize();
    }, false);


    adjustWindowToHash();
    updateNavigator();
  };

  window.onload = () => initialize();
});
